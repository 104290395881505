import styled from "styled-components";
const Container = styled.div`
 margin-top:5%;
 margin-bottom:5%;
 font-size: 18px;
`;

const DownloadButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  &:hover{
    text-decoration-color: #3EC1D5; 
    color : #3EC1D6;
  }
`;

const Table = styled.table`
  border-top: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
`;

const TechSupport = () =>{
  const files = [
    { id: 1, name: "WaterAIVIewer v1.0.1 설치 파일", url: "/support/WaterAIVIewerSetup.zip" },
    { id: 2, name: "WaterAIControl v1.0.0 모바일 앱 설치 파일(안드로이드 전용)", url: "/support/app-release.zip" },
    // { id: 3, name: "Test Document", url: "/files/test.docx" },
  ];
  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };
  return(
    <div style={{
      display:"flex",
      flexDirection:"column"
    }}>
      <div style={{width:"100%",height:"70px",backgroundColor:"black"}}/>
      <Container className="container" style={{}}>
      <div id="contact" className="contact-area">
        <div className="contact-inner area-padding">
          <div className="contact-overly"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>다운로드</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
              <Table>
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2",fontSize:"20px" }}>
                    <th style={{width:"20%",width:"20%",padding: "10px"}}>ID</th>
                    <th style={{width:"60%",padding: "10px"}}>파일 이름</th>
                    <th style={{width:"20%",padding: "10px"}}>다운로드</th>
                  </tr>
                </thead>
                <tbody>
                {files.map((file) => (
                  <tr key={file.id}>
                    <td style={{width:"20%", border: "0px solid black", padding: "10px" }}>{file.id}</td>
                    <td style={{width:"60%", border: "0px solid black", padding: "10px" }}>{file.name}</td>
                    <td style={{width:"20%", border: "0px solid black", padding: "10px" }}>
                      <DownloadButton   onClick={() => handleDownload(file.url, file.name)}>다운로드</DownloadButton>
                    </td>
                  </tr>
                 ))}
                </tbody>
              </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      </Container>

    </div>
  )

}
export default TechSupport;